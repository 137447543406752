*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body{
  overflow-x: hidden;
}
.card{
  height: 100% !important;
}
.card, .btn, .modal, input, select{
  border-radius: 0 !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.custom-file-input {
  color: transparent;
  width: fit-content;
}
.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
  
}
.custom-file-input::before {
  content:  attr(data-parent);
  display: inline-block;
  color: black;
  background: linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}
.custom-file-input:hover::before {
  border-color: black;
}
.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}
.product-verification-main-image{
  width: 100%;
  height: 500px;
  object-fit: contain;
  aspect-ratio: 3/2;
}
.product-verification-sub-image{
  width: 100%;
  height: 60px;
  object-fit: contain;
  aspect-ratio: 3/2;

}
.onHover-edit-image{
  position: relative;
  
}
.onHover-edit-image:hover .choose-image-label{
  opacity: 1;
  transition: 250ms ease-in;
  cursor: pointer;
}
.choose-image-label{
  position: absolute;
  right: 2px;
  top: 0;
  z-index: 2;
  opacity: 0;
  transition: 250ms ease-out;
  cursor: pointer;
}

.choose-image{
  position: absolute;
  right: 0;
  z-index: 5;
  top: 0;
  width: 100%;
  opacity: 0;
  cursor: pointer;

}

@media screen and (max-width : 950px) {
  .product-verification-main-image{
    width: 100%;
    height: 400px;
    object-fit: contain;
    aspect-ratio: 3/2;
  }
  
}